export const SIGNED_OUT = '@@AUTH/SIGNED_OUT';
export const SIGNED_IN = '@@AUTH/SIGNED_IN';
export const SIGN_IN_CODE_CONFIRMATION = '@@AUTH/SIGN_IN_CODE_CONFIRMATION';
export const SIGN_IN_FAILURE = '@@AUTH/SIGN_IN_FAILURE';
export const SIGNED_UP = '@@AUTH/SIGNED_UP';
export const SIGN_UP_FAILURE = '@@AUTH/SIGN_UP_FAILURE';
export const VERIFY_USER = '@@AUTH/VERIFY_USER';
export const CODE_CONFIRM_FAILURE = '@@AUTH/CODE_CONFIRM_FAILURE';
export const CODE_CONFIRMED = '@@AUTH/CODE_CONFIRMED';
export const SEND_NEW_CONFIRM_FAILURE = '@@AUTH/SEND_NEW_CONFIRM_FAILURE';
export const SEND_NEW_CONFIRM_SUCCESS = '@@AUTH/SEND_NEW_CONFIRM_SUCCESS';
export const UNDO_VERIFICATION_CODE = '@@AUTH/UNDO_VERIFICATION_CODE';
export const FORGOT_PASSWORD = '@@AUTH/FORGOT_PASSWORD';
export const PASSWORD_RESET = '@@AUTH/PASSWORD_RESET';
export const PASSWORD_RESET_FAILED = '@@AUTH/PASSWORD_RESET_FAILED';
export const LOADING = '@@AUTH/LOADING';
