import React from 'react';

export type MeepleColor = 'green' | 'red' | 'blue' | 'yellow' | 'black' | 'gray';

export const mapColor = new Map<MeepleColor, string>([
  ['green', '#689f38'],
  ['red', '#d32f2f'],
  ['blue', '#1e88e5'],
  ['yellow', '#fdd835'],
  ['black', '#263238'],
  ['gray', '#9e9e9e'],
]);

export const Meeple = (props: any) => (
  <svg fill="currentColor" viewBox="-5 -5 110 110" {...props} width="1em" height="1em">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.992 43.019c.578-13.757-32.354-20.455-33.781-22.023C65.5 20.216 67.861-.015 50 0 32.139-.015 34.499 20.216 33.788 20.996 32.361 22.564-.569 29.262.008 43.019c.576 13.758 18.051 6.692 21.778 10.741 3.26 3.544-15.279 26.229-17.353 40.634C3.757 99.089 5.077 100 9.64 100c8.276 0 16.177-.005 23.453-.005 3.287 0 4.456-1.889 6.152-4.492C42.999 89.741 47.533 80.479 50 80.48c2.466-.001 7.001 9.261 10.755 15.022 1.696 2.604 2.864 4.492 6.151 4.492 7.275 0 15.177.005 23.453.005 4.563 0 5.884-.911 5.207-5.606-2.073-14.405-20.611-37.09-17.353-40.634 3.727-4.048 21.204 3.018 21.779-10.74z"
    />
  </svg>
);
